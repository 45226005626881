@import '../../../../styles/customMediaQueries.css';

.heading {
  font-size: 32px;
  width: 100%;
  text-align: center;
}

.subheading {
  max-width: 65ch;
  font-size: 16px;
  line-height: 22.4px;
  color: var(--colorGrey500);
  text-align: center;
  text-wrap: balance;
  margin: 0px auto;
}

.productsSlider {
  margin: 0px auto;
  margin-top: 75px;
  margin-bottom: 100px;
  padding: 0 16px;
  max-width: 100vw;
  box-sizing: border-box;
  
  @media (--viewportLarge) {
    max-width: var(--contentMaxWidthPages);
  }

  & :global(.slick-slider) {
    margin-inline: -32px;
  }
  
  & :global(.slick-slide > div) {
    margin-inline: 16px;
  }
}

.customArrow {
  z-index: 10;
  background-color: white;
  position: absolute;
  top: 35%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 10px;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(26, 26, 26, 0.16) 0px 2px 8px 0px;
  & :global(svg) {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
